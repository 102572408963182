import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SocketIODispatcher from "./SocketIODispatcher";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { SocketContext, socket } from "./SocketIOContext";
// import { useAuth } from "services/Auth";
// import NoAccess from "./no-access";

export const AppViews = () => {
  // const { auth } = useAuth();

  // const isAdmin = auth?.data?.role?.includes("Administrador");

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <SocketContext.Provider value={socket}>
        <SocketIODispatcher />
        <Switch>
          <Route path={`${APP_PREFIX_PATH}/global/avatar`} component={lazy(() => import(`./avatar`))} />
          <Route
            path={`${APP_PREFIX_PATH}/global/qualification`}
            component={lazy(() => import(`./qualification`))}
          />
          <Route path={`${APP_PREFIX_PATH}/global/pier`} component={lazy(() => import(`./pier`))} />
          <Route
            path={`${APP_PREFIX_PATH}/global/pier-locations`}
            component={lazy(() => import(`./pier-locations`))}
          />

          <Route path={`${APP_PREFIX_PATH}/global/equipment`} component={lazy(() => import(`./equipment`))} />
          <Route path={`${APP_PREFIX_PATH}/staff`} component={lazy(() => import(`./staff`))} />
          <Route
            path={`${APP_PREFIX_PATH}/global/classification`}
            component={lazy(() => import(`./classification`))}
          />
          <Route path={`${APP_PREFIX_PATH}/tables`} component={lazy(() => import(`./tables`))} />
          <Route path={`${APP_PREFIX_PATH}/global/products`} component={lazy(() => import(`./products`))} />
          <Route path={`${APP_PREFIX_PATH}/global/vouchers`} component={lazy(() => import(`./vouchers`))} />
          <Route path={`${APP_PREFIX_PATH}/entry`} component={lazy(() => import(`./entry`))} />
          <Route path={`${APP_PREFIX_PATH}/service`} component={lazy(() => import(`./service`))} />

          <Route path={`${APP_PREFIX_PATH}/invoicing`} component={lazy(() => import(`./invoicing`))} />

          <Route path={`${APP_PREFIX_PATH}/tools`} component={lazy(() => import(`./tools`))} />

          <Route path={`${APP_PREFIX_PATH}/pier-dashboard`} component={lazy(() => import(`./pier-dashboard`))} />
          <Route path={`${APP_PREFIX_PATH}/comercial`} component={lazy(() => import(`./comercial`))} />
          <Route path={`${APP_PREFIX_PATH}/goods`} component={lazy(() => import(`./goods`))} />

          <Route
            path={`${APP_PREFIX_PATH}/bank-reconciliation`}
            component={lazy(() => import(`./bank-reconciliation`))}
          />

          <Route path={`${APP_PREFIX_PATH}/audit`} component={lazy(() => import(`./audit`))} />

          <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />

          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
        </Switch>
      </SocketContext.Provider>
    </Suspense>
  );
};

export default React.memo(AppViews);
