import { axiosAPIRequest, checkAuth } from "../index";

export const entries_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    let _filter = "";
    if (filter !== undefined) {
      _filter = `/?filter=${filter}`;
    }
    axiosAPIRequest(auth_data)
      .get(`/entry${_filter}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entriesV2_get = async (auth_data, limit, page, filter, select, orderBy, orderDirection) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get("/entry/v2", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entries_without_billing_get = async (
  auth_data,
  limit,
  page,
  filter,
  select,
  orderBy,
  orderDirection
) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get("/entry/without-billing", {
        params: {
          limit,
          page,
          filter,
          select,
          orderBy,
          orderDirection,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const regular_schedules_get = async (auth_data, date) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get("/entry/regular-schedules", {
        params: {
          date,
          t: new Date().getTime(),
        },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entries_active_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get("/entry/active")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entries_entry_passengers_get = async (auth_data, entry_id_raw) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/entry-passengers/${entry_id_raw}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_calc_v2_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/entry-calc-v2", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entries_entry_passengers_post = async (auth_data, entry_id_raw, payload) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post(`/entry/entry-passengers/${entry_id_raw}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entries_list_get = async (auth_data, entriesArray) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/list/${entriesArray}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_get = async (auth_data, id, xtra = "") => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/${id}${xtra}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_active_get = async (auth_data, entry_id_raw) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/active/${entry_id_raw}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_navigation_get = async (auth_data, entry_id) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/navigation/${entry_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_from_pier_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/from-pier", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_recalculate_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/recalculate-entry", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
// export const entry_post_newversion = async (auth_data, entry_id, body) => {
//   return new Promise((resolve, reject) => {
//     axiosAPIRequest(auth_data)
//       .post(`/entry/${entry_id}`, body)
//       .then((res) => resolve(res))
//       .catch((err) => {
//         checkAuth(err);
//         reject(err);
//       });
//   });
// };

export const entry_put = async (auth_data, _id, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .put(`/entry/${_id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_delete = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .delete(`/entry/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_related_eligible_get = async (auth_data, entry_id_raw, entity_id) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/related-eligible/${entry_id_raw}/${entity_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_related_get = async (auth_data, entry_id_raw) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/related/${entry_id_raw}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_related_put = async (auth_data, entry_id_raw, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .put(`/entry/related/${entry_id_raw}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

///entry-calc/:entry_id

export const entry_calc_get = async (auth_data, entry_id) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .get(`/entry/entry-calc/${entry_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_calc_put = async (auth_data, entry__id, payload) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .put(`/entry/entry-calc-values/${entry__id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_calc_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/entry-calc-values", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_check_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/entry-check", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_send_email_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/send-entry-email", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const entry_duplicate_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    axiosAPIRequest(auth_data)
      .post("/entry/duplicate", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
