import axios from "axios";

import axiosRetry from "axios-retry";

const api_request = () => {
  const _axios = new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  axiosRetry(_axios, { retries: 3, shouldResetTimeout: true });
  return _axios;
};
const checkAuth = (resp) => {
  //TODO:
  //Impement on components on catch: If status is 401 execute Google Signout
  // console.log(resp.response.status);
};
export const equipments_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    let _filter = "";
    if (filter !== undefined) {
      _filter = `/?filter=${filter}`;
    }
    api_request(auth_data)
      .get(`/equipment${_filter}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
export const equipments_get_v2 = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/equipment/v2", {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipments_occupation_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/equipment/occupation")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipments_active_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/equipment/active")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipments_fuel_km_control_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/equipment/fuel-km-control")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipment_get = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/equipment/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipment_post = async (auth_data, body) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/equipment", body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipment_put = async (auth_data, body, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/equipment/${id}`, body)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const equipment_delete = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/equipment/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
