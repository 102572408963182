import { Modal } from "antd";

export const accessTypes = [
  "Administrador",
  "Gestor",
  "Administrativo",
  "Secretariado",
  //"Marketing",
  "Cais",
  "Comprador",
  "Mobile: Despesas",
  "Ocupação (ViewOnly)",
];

const rolesRank = {
  Administrador: 5,
  Gestor: 4,
  Administrativo: 3,
  //Marketing: 3,
  Secretariado: 2,
  Cais: 1,
  Comprador: 0,
  "Mobile: Despesas": 0,
  "Ocupação (ViewOnly)": -1,
};

export const getRoleRank = (roles) => {
  let rank = 1;
  if (!roles) return rank;

  roles.forEach((role) => {
    if (rolesRank[role] > rank) {
      rank = rolesRank[role];
    }
  });

  return rank;
};

export const checkPermissions = (role, auth, showModal = false) => {
  if (!auth.permissions || !role) {
    showModal &&
      Modal.error({
        title: "Aviso!",
        content: "Algo de muito errado não está certo.",
      });
    return false;
  }
  const allowed = auth.permissions.includes(role);
  if (allowed === false) {
    showModal &&
      Modal.error({
        title: "Aviso!",
        content: "Não tem permissão para realizar esta ação.",
      });
  }

  return allowed;
};
