import { staff_delete, staffs_get } from "api/staff";
import {
  add_contract,
  delete_contract,
  edit_contract,
  get_contracts,
  get_staff_contracts,
} from "api/staff/contracts";
import {
  add_occupation,
  delete_occupation,
  edit_occupation,
  get_occupations,
  get_occupations_by_staff_and_date,
  get_occupations_totals,
  get_occupations_all_totals,
} from "api/staff/occupations";
import { get_shifts } from "api/staff/shifts";
import { staff_workplaces_active_get } from "api/staff/workplaces";
import {
  add_time_clock,
  delete_time_clock,
  edit_time_clock,
  get_time_clock_by_staff,
  get_time_clock_by_staff_and_date,
  get_time_clocks,
} from "api/staff/time-clocks";
import { get_roles } from "api/staff/roles";
import {
  create_occurrence,
  delete_occurrence,
  get_occurrences,
  update_occurrence,
} from "api/staff/occurrences";
import { create_training, delete_training, get_trainings, update_training } from "api/staff/trainings";
import {
  create_unavailability,
  delete_unavailability,
  get_unavailabilites,
  update_unavailability,
} from "api/staff/unavailability";
import { create_total, delete_total, get_calculation, get_totals, update_total } from "api/staff/totals";
import {
  recalculation,
  get_timeline,
  reset_timeline,
  delete_occupation_timeclock,
  create_occupation_timeclock,
} from "api/staff/timeline";

//Staff---------------------------------------------------------------------------
export const dbAllStaffGet = (data) => {
  return new Promise((resolve) => {
    try {
      staffs_get("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbStaffDelete = (data) => {
  return new Promise((resolve) => {
    try {
      staff_delete("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));

      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbGetStaffById = (id) => {
  return new Promise((resolve) => {
    try {
      // get_staff_by_id("", id)
      //   .then((res) => resolve({ status: true, data: res.data }))
      //   .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
//Contracts---------------------------------------------------------------------------

export const dbStaffContractsGet = (id) => {
  return new Promise((resolve) => {
    try {
      get_staff_contracts("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbAllContractsGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_contracts("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbStaffContractDelete = (data) => {
  return new Promise((resolve) => {
    try {
      delete_contract("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));

      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbContractPost = (payload) => {
  return new Promise((resolve) => {
    try {
      add_contract("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbContractPut = (id, payload) => {
  return new Promise((resolve) => {
    try {
      edit_contract("", id, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//Occupations---------------------------------------------------------------------------

export const dbOccupationsGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_occupations("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccupationsTotalsGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_occupations_totals("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccupationsAllTotalsGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_occupations_all_totals("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccupationsPost = (payload) => {
  return new Promise((resolve) => {
    try {
      add_occupation("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccupationsPut = (id, payload) => {
  return new Promise((resolve) => {
    try {
      edit_occupation("", id, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccupationsDelete = (id) => {
  return new Promise((resolve) => {
    try {
      delete_occupation("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccupationCheckDate = (payload) => {
  return new Promise((resolve) => {
    try {
      get_occupations_by_staff_and_date("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const dbShiftsGet = () => {
  return new Promise((resolve) => {
    try {
      get_shifts("")
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//Totals-------------------------------------------------------------------------------

export const dbTotalsGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_totals("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbCalculations = (data) => {
  return new Promise((resolve) => {
    try {
      get_calculation("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTotalsPost = (data) => {
  return new Promise((resolve) => {
    try {
      create_total("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTotalsPut = (id, data) => {
  return new Promise((resolve) => {
    try {
      update_total("", id, data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTotalsDelete = (id) => {
  return new Promise((resolve) => {
    try {
      delete_total("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//Workplaces---------------------------------------------------------------------------

export const dbWorkplacesGet = () => {
  return new Promise((resolve) => {
    const filter = { active: true };
    try {
      staff_workplaces_active_get("", filter)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
//Roles---------------------------------------------------------------------------

export const dbRolesGet = () => {
  return new Promise((resolve) => {
    try {
      get_roles("")
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));

      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//TimeClock---------------------------------------------------------------------------

export const dbTimeClockGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_time_clocks("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTimeClockByStaffGet = (staffId) => {
  return new Promise((resolve) => {
    try {
      get_time_clock_by_staff("", staffId)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const dbTimeClockPost = (payload) => {
  return new Promise((resolve) => {
    try {
      add_time_clock("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const dbTimeClockPut = (id, payload) => {
  return new Promise((resolve) => {
    try {
      edit_time_clock("", id, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const dbTimeClockDelete = (id) => {
  return new Promise((resolve) => {
    try {
      delete_time_clock("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

export const dbTimeClockCheckDate = (payload) => {
  return new Promise((resolve) => {
    try {
      get_time_clock_by_staff_and_date("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//Occurrences---------------------------------------------------------------------------

export const dbOccurrencesGet = async (filter) => {
  return new Promise((resolve) => {
    try {
      get_occurrences("", filter)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccurrencesPost = async (payload) => {
  return new Promise((resolve) => {
    try {
      create_occurrence("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccurrencesPut = async (id, payload) => {
  return new Promise((resolve) => {
    try {
      update_occurrence("", id, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbOccurrencesDelete = async (id) => {
  return new Promise((resolve) => {
    try {
      delete_occurrence("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//Trainings---------------------------------------------------------------------------

export const dbTrainingsGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_trainings("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTrainingPost = (payload) => {
  return new Promise((resolve) => {
    try {
      create_training("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTrainingPut = (id, payload) => {
  return new Promise((resolve) => {
    try {
      update_training("", id, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTrainingDelete = (id) => {
  return new Promise((resolve) => {
    try {
      delete_training("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
//Unavailabilities--------------------------------------------------------
export const dbUnavailabilitiesGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_unavailabilites("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbUnavailabilityPost = (payload) => {
  return new Promise((resolve) => {
    try {
      create_unavailability("", payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbUnavailabilityPut = (id, payload) => {
  return new Promise((resolve) => {
    try {
      update_unavailability("", id, payload)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbUnavailabilityDelete = (id) => {
  return new Promise((resolve) => {
    try {
      delete_unavailability("", id)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};

//Timeline---------------------------------------------------------------
export const dbTimelineGet = (data) => {
  return new Promise((resolve) => {
    try {
      get_timeline("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbRecalculation = (data) => {
  return new Promise((resolve) => {
    try {
      recalculation("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTimelinePost = (data) => {
  return new Promise((resolve) => {
    try {
      create_occupation_timeclock("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
export const dbTimelineDelete = (data) => {
  return new Promise((resolve) => {
    try {
      delete_occupation_timeclock("", data)
        .then((res) => resolve({ status: true, data: res.data }))
        .catch((err) => resolve({ status: false, data: err }));
      // Error handling
    } catch (error) {
      resolve({ status: false, data: error });
    }
  });
};
