import { Alert, Button } from "antd";
import React, { useEffect, useState } from "react";
import OccupationMap from "views/app-views/service/occupation-map";
import MobileSchedule from "views/app-views/staff/mobileSchedule";
import { TeamOutlined, CalendarOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useAuth } from "services/Auth";
import { checkPermissions } from "utils/roles";
import Unauthorized from "utils/Unauthorized";

function MobileViews() {
  const [view, setView] = useState(null);
  const { auth } = useAuth();
  const [width, setWidth] = useState(window.innerWidth);

  const handleBack = () => {
    setView(null);
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    window.addEventListener("orientationchange", function () {
      setWidth(window.innerWidth);
      console.log("orientationchange");
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  if (view === "occupation") {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <OccupationMap readOnly={true} handleBack={handleBack} />
      </div>
    );
  }

  if (view === "schedule") {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <MobileSchedule handleBack={handleBack} />
      </div>
    );
  }
  if (checkPermissions("mobile", auth.data, false) === false) {
    return <Unauthorized />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "24px",
        backgroundColor: "#f5f7fa",
      }}
    >
      {width < 667 ? (
        <div>
          <h4 className="text-center mb-3 mt-3">
            <strong>Visualização indisponível na vertical</strong>
          </h4>
          <Alert
            message="Roda o telemóvel na horizontal"
            description="Para visualizar a ocupação da equipa, roda o telemóvel na horizontal."
            type="info"
          />
        </div>
      ) : (
        <div
          style={{ width: "100%", maxWidth: "280px", display: "flex", flexDirection: "column", gap: "24px" }}
        >
          <Button
            size="large"
            type="primary"
            onClick={() => setView("occupation")}
            style={{
              height: "80px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TeamOutlined style={{ fontSize: "24px", marginBottom: "8px" }} />
            <span>Mapa de Ocupação</span>
          </Button>

          <Button
            size="large"
            type="primary"
            onClick={() => setView("schedule")}
            style={{
              height: "80px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CalendarOutlined style={{ fontSize: "24px", marginBottom: "8px" }} />
            <span>Escala de Staff</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default MobileViews;
