import { api_request, checkAuth } from "../index";

export const staff_workplaces_active_get = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/staff/workplaces", {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_workplaces_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/staff/workplaces")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_workplace_get = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/staff/workplaces/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_workplaces_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/staff/workplaces", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_workplaces_put = async (auth_data, id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/staff/workplaces/${id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const staff_workplaces_delete = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/staff/workplaces/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
