import { api_request, checkAuth } from "../index";

export const get_time_clock_by_staff = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/staff/time-clocks`, {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const delete_time_clock = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/staff/time-clocks/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const add_time_clock = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/staff/time-clocks`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const edit_time_clock = async (auth_data, id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/staff/time-clocks/${id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const get_time_clocks = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/staff/time-clocks/`, {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const get_time_clock_by_staff_and_date = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/staff/time-clocks/check`, {
        params: payload,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
