import React, { useState, useEffect } from "react";
import { Form, Input, Card, Button, Typography, notification, Modal, Spin, Alert } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import OccupationMap from "../app-views/service/occupation-map";
import { otp_request_user, otp_authenticate_user, otp_auth_user } from "api/auth";
import MobileSchedule from "views/app-views/staff/mobileSchedule";

const { Title } = Typography;

const isNullUndefinedOrEmpty = (value) => {
  return value === null || value === "" || value === undefined || value === "undefined";
};

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Oops...</div>;
  }

  return (
    <div className="timer">
      <div className="text">Faltam</div>
      <div className="value">{remainingTime}</div>
      <div className="text">segundos</div>
    </div>
  );
};

const TeamOccupation = () => {
  const [form] = Form.useForm();
  const [authStep, setAuthStep] = useState(0);
  const [phone, setPhone] = useState("");
  const [phoneValidatonCode, setPhoneValidatonCode] = useState("");
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [timerDuration, setTimerDuration] = useState(120);
  const [scheduleVisible, setScheduleVisible] = useState(false);

  // Get user viewport width
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (authStep === 0 && userAuthenticated !== true) {
      checkAuth();
    }
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    window.addEventListener("orientationchange", function () {
      setWidth(window.innerWidth);
      console.log("orientationchange");
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  // useEffect(() => {
  //   if (window.innerWidth !== width) {
  //     console.log("Width changed");
  //     setWidth(window.innerWidth);
  //   }
  // }, [window]);

  const checkAuth = async () => {
    // check if user is authenticated

    let mdAuth = {};
    try {
      mdAuth = JSON.parse(window.localStorage.getItem("md-auth"));
    } catch (error) {}

    if (isNullUndefinedOrEmpty(mdAuth?.token)) {
      setAuthStep(1);
      return;
    }

    // Check if the phone token is valid
    const authResponse = await dbPostOTPAuth(mdAuth.token);
    if (!authResponse.status) {
      setAuthStep(1);
      return;
    }

    setUserAuthenticated(true);
  };

  const confirmPhoneHandler = async (values) => {
    if (values?.verified_phone?.length < 9) {
      notification.error({
        message: "Oops!",
        description: "Número de telefone inválido.",
      });
      return;
    }

    // Check if the phone number starts with 9
    if (values?.verified_phone?.charAt(0) !== "9") {
      notification.error({
        message: "Oops!",
        description: "O número não parece ser um número de telemóvel válido.",
      });
      return;
    }

    const response = await dbPostOTPRequest(values.verified_phone);
    if (!response.status) {
      Modal.error({
        title: "Erro",
        content:
          "Erro ao efetuar o pedido! Verifica se o número de telefone está correto, e se tens acesso à internet.",
      });

      return;
    }

    setPhone(values.verified_phone);
    setTimerDuration(120);
    setAuthStep(2);
  };

  const dbPostOTPAuth = async (phone_token) => {
    return new Promise((resolve) => {
      try {
        otp_auth_user("", { phone_token })
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error Handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbPostOTPRequest = async (verified_phone) => {
    return new Promise((resolve) => {
      try {
        otp_request_user("", { verified_phone })
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error Handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbPostOTPAuthenticate = async (verified_phone, phone_code) => {
    return new Promise((resolve) => {
      try {
        otp_authenticate_user("", { verified_phone, phone_code })
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error Handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };
  const validate2FAHandler = async (code) => {
    const response = await dbPostOTPAuthenticate(phone, code);
    if (!response.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível validar o código. Verifica se o código está correto.",
      });
      return;
    }
    localStorage.setItem("MD.phone_token", response.data.phone_token);
    setUserAuthenticated(true);
  };

  return (
    <div style={{ margin: 10 }}>
      {userAuthenticated !== true ? (
        <div className="container" style={{ maxWidth: 500 }}>
          <Card>
            {authStep !== 0 && <h2 className="text-center mb-5">Autenticação </h2>}
            <Form layout="vertical" form={form} onFinish={confirmPhoneHandler}>
              {authStep === 0 && (
                <div className="text-center m-5 p-5">
                  <Spin size="large" spinning />
                </div>
              )}
              {authStep === 1 && (
                <>
                  <Form.Item
                    label="Número de Telefone"
                    name="verified_phone"
                    rules={[{ required: true, message: "Indica o teu número de telefone." }]}
                  >
                    <Input type="number" inputMode="numeric" />
                  </Form.Item>
                  <Form.Item>
                    <div className="text-right">
                      <Button type="primary" htmlType="submit">
                        Confirmar <RightCircleOutlined />
                      </Button>
                    </div>
                  </Form.Item>
                </>
              )}
            </Form>
            {authStep === 2 && (
              <>
                <div className="phoneOtpContainer">
                  <div className="phoneOtpContainerTitle">
                    <Title level={4}>Introduz o código enviado para o teu telemóvel</Title>
                  </div>
                  <div className="phoneOtpContainerInput">
                    <OtpInput
                      inputMode="numeric"
                      shouldAutoFocus
                      className="phoneOtp"
                      numInputs={4}
                      value={phoneValidatonCode}
                      onChange={(e) => {
                        setPhoneValidatonCode(e);
                        if (e.length === 4) {
                          validate2FAHandler(e);
                        }
                      }}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <Input
                          {...props}
                          style={{
                            width: 60,
                            marginRight: 6,
                            marginLeft: 6,
                            fontSize: 25,
                            textAlign: "center",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="phoneOtpContainerCountdown">
                    <CountdownCircleTimer
                      isPlaying
                      size={140}
                      colors={["#59cf15", "#cfc615", "#cfa115", "#cf2e15"]}
                      duration={timerDuration}
                      colorsTime={[120, 30, 15, 0]}
                      onComplete={() => {
                        //  setPhoneValidationModal({ visible: false, phone: null, loading: false });
                        setPhoneValidatonCode("");
                        setAuthStep(1);

                        notification.error({
                          message: "Oops!",
                          description: "O tempo para introduzir o código expirou.",
                        });
                      }}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                </div>
              </>
            )}
          </Card>
        </div>
      ) : (
        <>
          {width < 667 ? (
            <div>
              <h4 className="text-center mb-3 mt-3">
                <strong>Visualização indisponível na vertical</strong>
              </h4>
              <Alert
                message="Roda o telemóvel na horizontal"
                description="Para visualizar a ocupação da equipa, roda o telemóvel na horizontal."
                type="info"
              />
            </div>
          ) : (
            <div>
              <Button
                type="link"
                onClick={() => {
                  if (scheduleVisible === false) setScheduleVisible(true);
                  else setScheduleVisible(false);
                }}
              >
                {!scheduleVisible && "Escala"}
                {scheduleVisible && "Ocupação"}
              </Button>
              {scheduleVisible && <MobileSchedule />}
              {!scheduleVisible && <OccupationMap readOnly={true} />}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TeamOccupation;
