import { api_request, checkAuth } from "../index";

export const get_contracts = async (auth_data, filter) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/staff/contracts", {
        params: filter,
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const get_staff_contracts = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/staff/contracts", {
        params: { filter: { staff: id } },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const delete_contract = async (auth_data, id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/staff/contracts/${id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
export const add_contract = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    console.log(payload);
    api_request(auth_data)
      .post(`/staff/contracts`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
export const edit_contract = async (auth_data, id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/staff/contracts/${id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
