import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Unauthorized(props) {
  const { mobile } = props;
  console.log(mobile);
  return (
    <Result
      status="500"
      title="Aviso!"
      subTitle="Não tens permissão para aceder a esta página."
      extra={<Link to="/">{!mobile && <Button type="primary">Voltar</Button>}</Link>}
    />
  );
}

export default Unauthorized;
